<template>
    <svg width="42" height="42" viewBox="0 0 24 24" fill="none" class="p-2" xmlns="http://www.w3.org/2000/svg">
        <path
            d="M6.67 14.2192H4C2.9 14.2192 2 15.1192 2 16.2192V21.2192C2 21.7692 2.45 22.2192 3 22.2192H6.67C7.22 22.2192 7.67 21.7692 7.67 21.2192V15.2192C7.67 14.6692 7.22 14.2192 6.67 14.2192Z"
            fill="#DD5928" />
        <path
            d="M13.3302 10.2192H10.6602C9.56016 10.2192 8.66016 11.1192 8.66016 12.2192V21.2192C8.66016 21.7692 9.11016 22.2192 9.66016 22.2192H14.3302C14.8802 22.2192 15.3302 21.7692 15.3302 21.2192V12.2192C15.3302 11.1192 14.4402 10.2192 13.3302 10.2192Z"
            fill="#DD5928" />
        <path
            d="M20.0001 17.2192H17.3301C16.7801 17.2192 16.3301 17.6692 16.3301 18.2192V21.2192C16.3301 21.7692 16.7801 22.2192 17.3301 22.2192H21.0001C21.5501 22.2192 22.0001 21.7692 22.0001 21.2192V19.2192C22.0001 18.1192 21.1001 17.2192 20.0001 17.2192Z"
            fill="#DD5928" />
        <path
            d="M15.0104 5.06922C15.3204 4.75922 15.4404 4.38922 15.3404 4.06922C15.2404 3.74922 14.9304 3.51922 14.4904 3.44922L13.5304 3.28922C13.4904 3.28922 13.4004 3.21922 13.3804 3.17922L12.8504 2.11922C12.4504 1.30922 11.5404 1.30922 11.1404 2.11922L10.6104 3.17922C10.6004 3.21922 10.5104 3.28922 10.4704 3.28922L9.51043 3.44922C9.07043 3.51922 8.77043 3.74922 8.66043 4.06922C8.56043 4.38922 8.68043 4.75922 8.99043 5.06922L9.73043 5.81922C9.77043 5.84922 9.80043 5.96922 9.79043 6.00922L9.58043 6.92922C9.42043 7.61922 9.68043 7.92922 9.85043 8.04922C10.0204 8.16922 10.3904 8.32922 11.0004 7.96922L11.9004 7.43922C11.9404 7.40922 12.0704 7.40922 12.1104 7.43922L13.0004 7.96922C13.2804 8.13922 13.5104 8.18922 13.6904 8.18922C13.9004 8.18922 14.0504 8.10922 14.1404 8.04922C14.3104 7.92922 14.5704 7.61922 14.4104 6.92922L14.2004 6.00922C14.1904 5.95922 14.2204 5.84922 14.2604 5.81922L15.0104 5.06922Z"
            fill="#DD5928" />
    </svg>
</template>