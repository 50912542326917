<template>
    <svg width="42" height="42" viewBox="0 0 24 24" fill="none" class="p-2" xmlns="http://www.w3.org/2000/svg">
        <path
            d="M17 7C17 9.76142 14.7614 12 12 12C9.23858 12 7 9.76142 7 7C7 4.23858 9.23858 2 12 2C14.7614 2 17 4.23858 17 7Z"
            fill="#DD5928" stroke="#DD5928" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
        <path d="M3.41016 22C3.41016 18.13 7.26015 15 12.0002 15C12.9602 15 13.8902 15.13 14.7602 15.37"
            stroke="#DD5928" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
        <path
            d="M22 18C22 18.32 21.96 18.63 21.88 18.93C21.79 19.33 21.63 19.72 21.42 20.06C20.73 21.22 19.46 22 18 22C16.97 22 16.04 21.61 15.34 20.97C15.04 20.71 14.78 20.4 14.58 20.06C14.21 19.46 14 18.75 14 18C14 16.92 14.43 15.93 15.13 15.21C15.86 14.46 16.88 14 18 14C19.18 14 20.25 14.51 20.97 15.33C21.61 16.04 22 16.98 22 18Z"
            stroke="#DD5928" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
        <path d="M19.4897 17.98H16.5098" stroke="#DD5928" stroke-width="1.5" stroke-miterlimit="10"
            stroke-linecap="round" stroke-linejoin="round" />
        <path d="M18 16.52V19.51" stroke="#DD5928" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round"
            stroke-linejoin="round" />
        <path
            d="M5.50003 17.9998C8.97837 14.4869 14.5 15.5 14.5 15.5C13.3109 18.687 14.0635 20.3674 18.0021 22.7729H3.29297C3.29297 22.7729 4.20393 19.3088 5.50003 17.9998Z"
            fill="#DD5928" />
    </svg>
</template>