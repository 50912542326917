import { createApp, provide, h } from "vue";
import App from "./App.vue";

//Sentry Integration
import { createPinia } from "pinia";
import piniaPluginPersistedstate from "pinia-plugin-persistedstate";
import "./registerServiceWorker";
import router from "./router";
import "./styles/app.css";
import "./assets/global.scss";
import { getToken } from "./services/auth";
import { ApolloClient, createHttpLink, InMemoryCache,ApolloLink, concat, split } from "@apollo/client/core";
import { ApolloClients } from "@vue/apollo-composable";
import { GraphQLWsLink } from "@apollo/client/link/subscriptions";
import { createClient } from "graphql-ws";
import { getMainDefinition } from "@apollo/client/utilities";
import "gitart-vue-dialog/dist/style.css";
import { GDialog } from "gitart-vue-dialog";

declare global {
  interface GlobalThis {
    __VUE_OPTIONS_API__: boolean;
  }
}
//@ts-ignore-next-line
globalThis.__VUE_PROD_HYDRATION_MISMATCH_DETAILS__ = true;

// HTTP connection to the API
const httpLink = createHttpLink({
  // You should use an absolute URL here
  uri: process.env.VUE_APP_API,
});

const subscriptionUrl = process.env.VUE_APP_SUBSCRIPTION_URL ?? "";
const wsLink = new GraphQLWsLink(
  createClient({
    url: subscriptionUrl,
  })
);



// Cache implementation
const cache = new InMemoryCache();

//Apollo Link for token
const auth: ApolloLink = new ApolloLink((operation, forward) => {
    // add the authorization to the headers
    operation.setContext(({ headers = {} }) => ({
      headers: {
        ...headers,
        authorization: "Bearer " + getToken() || null,
      }
    }));
  
    return forward(operation);
  });


  const WsAndHttpLink = split(
    // split based on operation type
    ({ query }) => {
      const definition = getMainDefinition(query);
      return (
        definition.kind === "OperationDefinition" &&
        definition.operation === "subscription"
      );
    },
    wsLink,
    httpLink
  );

// Create the apollo client
const apolloClient = new ApolloClient({
  // link: concat(auth, httpLink),
  link: concat(auth, WsAndHttpLink),
  cache,
});

const pinia = createPinia(); // for state management instead of vuex
pinia.use(piniaPluginPersistedstate);

const app = createApp({
  setup () {
    provide(ApolloClients, {
      default: apolloClient,
    });
  },

  render: () => h(App),
});

app.use(pinia)
.use(router)
.component("GDialog", GDialog)
.mount("#app");

