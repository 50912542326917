import { Decrypt, Encrypt } from "@/services/crypto";
import { defineStore } from "pinia";
import { DomainsType, HeadType, ServiceType } from "../types/AuthTypes";

export const useAccessStore = defineStore({
  id: "accessLevel",
  state: () => ({
    activeAccessLevel: {
      id: 0 as number | undefined,
      name: "" as string | undefined,
      description: "" as string | undefined,
    },
    assigned_employees: [] as Array<HeadType>,
    services: [] as Array<ServiceType>,
    accessLevelEditState: false as boolean,
    permissions: {
      settings: {
        loans: {
          selected: 0,
          enable_all: false,
          view_loan_type: false,
          manage_loan_type: false,
          view_loan_forms: false,
          manage_loan_forms: false,
        },
        leave_management: {
          selected: 0,
          enable_all: false,
          view_leave_holidays: false,
          manage_leave_holidays: false,
          view_leave_type: false,
          manage_leave_type: false,
          view_leave_policy: false,
          manage_leave_policy: false,
          view_manager_dashboard: false,
          manage_manager_dashboard: false,
          view_hr_dashboard: false,
          manage_hr_dashboard: false,
        },
        general: {
          selected: 0,
          enable_all: false,
          view_organizations: false,
          manage_organizations: false,
          view_office: false,
          manage_office: false,
        },
        invoice: {
          selected: 0,
          enable_all: false,
          view_approvers: false,
          manage_approvers: false,
          view_rate: false,
          manage_rate: false,
        },
        employee_management: {
          selected: 0,
          enable_all: false,
          view_access_levels: false,
          manage_access_levels: false,
          view_departments: false,
          manage_departments: false,
          view_employment_types: false,
          manage_employment_types: false,
          view_id_prefix: false,
          manage_id_prefix: false,
          view_location: false,
          manage_location: false,
          view_position: false,
          manage_position: false,
        },
        performance_assessment: {
          selected: 0,
          enable_all: false,
          view_assessment_cycle: false,
          manage_assessment_cycle: false,
          view_competency: false,
          manage_competency: false,
          view_questionnaire: false,
          manage_questionnaire: false,
        },
        talent_management: {
          selected: 0,
          enable_all: false,
          view_specializations: false,
          manage_specializations: false,
          view_seniority_level: false,
          manage_seniority_level: false,
        },
        time_tracker: {
          selected: 0,
          enable_all: false,
          view_configurations: false,
          manage_configurations: false,
          view_categories: false,
          manage_categories: false,
        },
        approvers: {
          selected: 0,
          enable_all: false,
          view_loan_approver: false,
          manage_loan_approver: false,
          view_timesheet_approver: false,
          manage_timesheet_approver: false,
          manage_account_based_approver: false,
          manage_project_based_approver: false,
          view_leave_approvers: false,
          manage_leave_approvers: false,
        },
      },
      performance_assessment: {
        selected: 0,
        enable_all: false,
        view_assessment_manager: false,
        manage_assessment_manager: false,
        view_direct_response: false,
        manage_direct_response: false,
        view_manager_assessment: false,
        manage_manager_assessment: false,
        view_peer_assessment: false,
        manage_peer_assessment: false,
        view_peer_assignment: false,
        manage_peer_assignment: false,
        view_self_assessment: false,
        manage_self_assessment: false,
      },
      time_tracker: {
        selected: 0,
        enable_all: false,
        view_time_tracking: false,
        manage_time_tracking: false,
        view_categories: false,
        manage_categories: false,
        view_configurations: false,
        manage_configurations: false,
        view_project_team_sheet: false,
        manage_project_team_sheet: false,
        view_team_sheet: false,
        manage_team_sheet: false,
        view_basic_report :  false,
        manage_basic_report : false,
      },
      loan_manager: {
        selected: 0,
        enable_all: false,
        view_loans_manager: false,
        manage_loans_manager: false,
      },
      staff_directory: {
        selected: 0,
        enable_all: false,
        add_employee: false,
        deactivate_user: false,
        manage_employee: false,
        view_deactivated_users: false,
        view_active_users: false,
      },
      company_document: {
        selected: 0,
        enable_all: false,
        view_company_documents: false,
        manage_company_documents: false,
      },
      resource_manager: {
        selected: 0,
        enable_all: false,
        view_client_manager: false,
        view_skills_manager: false,
        view_project_manager: false,
        view_contract_manager: false,
        view_talent_pool: false,
        manage_client_manager: false,
        manage_skills_manager: false,
        manage_project_manager: false,
        manage_contract_manager: false,
        manage_talent_pool: false,
      },
      employee_personal_information: {
        work_info: {
          selected: 0,
          enable_all: false,
          view_employee_status: false,
          manage_employee_status: false,
          view_job_information: false,
          manage_job_information: false,
          view_salary_information: false,
          manage_salary_information: false,
        },
        edit_profile: {
          work_information: {
            selected: 0,
            enable_all: false,
            view_id: false,
            manage_id: false,
            view_hire_date: false,
            manage_hire_date: false,
            view_primary_specialization: false,
            manage_primary_specialization: false,
            view_secondary_specialization: false,
            manage_secondary_specialization: false,
            view_client_readiness: false,
            manage_client_readiness: false,
            view_is_manager: false,
            manage_is_manager: false,
            view_payroll_id: false,
            manage_payroll_id: false,
          },
          personal_information: {
            selected: 0,
            enable_all: false,
            manage_personal_information: false,
            manage_account_settings: false,
          },
        },
        employee_doc: {
          view_document: false,
          manage_document: false,
        },
        employee_loan: {
          view_loans: false,
          manage_loans: false,
        },
        personal_info: {
          view_skills: false,
          manage_skills: false,
          view_education: false,
          manage_eduction: false,
          view_bank_details: false,
          manage_bank_details: false,
          view_emergency_contact: false,
          manage_emergency_contact: false,
          view_relation_and_next_of_kin: false,
          manage_relation_and_next_of_kin: false,
          view_identification: false,
          manage_identification: false,
        },
        time_tracking: {
          view_time_tracking: false,
          manage_time_tracking: false,
          view_task_planner: false,
          manage_task_planner: false,
          view_preference: false,
          manage_preference: false,
        },
        employee_performance: {
          selected: 0,
          enable_all: false,
          view_manager_evaluation: false,
          manage_manager_evaluation: false,
          view_employee_evaluation: false,
          manage_employee_evaluation: false,
          view_cdc_decision: false,
          manage_cdc_decision: false,
          view_cdc_plan: false,
          manage_cdc_plan: false,
          view_appraisal: false,
          manage_appraisal: false,
          view_manager_comment: false,
          manage_manager_comment: false,
          view_upcoming_assessment: false,
          manage_upcoming_assessment: false,
          view_assessment_report: false,
          manage_assessment_report: false,
          view_peer_assessment: false,
          manage_peer_assessment: false,
          view_direct_assessment: false,
          manage_direct_assessment: false,
          view_performance_improvement_plan: false,
          manage_performance_improvement_plan: false,
          view_pip_stepper: false,
          manage_pip_stepper: false,
        },
        employee_project: {
          view_project: false,
        },
        notes: {
          selected: 0,
          enable_all: false,
          view_notes: false,
          manage_notes: false,
        },
        leaves: {
          selected: 0,
          enable_all: false,
          view_leaves: false,
          manage_leaves: false,
        },
        bonus_and_benefit: {
          selected: 0,
          enable_all: false,
          view_bonus: false,
          view_benefit: false,
          manage_benefit: false,
          view_tier: false,
          manage_tier: false,
        },
        procurement_requisition:{
          selected: 0,
          enable_all: false,
          view_requisitions:false,
          manage_requisition:false
        }
      },
      other_employee_personal_information: {
        work_info: {
          selected: 0,
          enable_all: false,
          view_employee_status: false,
          manage_employee_status: false,
          view_job_information: false,
          manage_job_information: false,
          view_salary_information: false,
          manage_salary_information: false,
        },
        edit_profile: {
          work_information: {
            selected: 0,
            enable_all: false,
            view_id: false,
            manage_id: false,
            view_hire_date: false,
            manage_hire_date: false,
            view_primary_specialization: false,
            manage_primary_specialization: false,
            view_secondary_specialization: false,
            manage_secondary_specialization: false,
            view_client_readiness: false,
            manage_client_readiness: false,
            view_is_manager: false,
            manage_is_manager: false,
            view_payroll_id: false,
            manage_payroll_id: false,
          },
          personal_information: {
            selected: 0,
            enable_all: false,
            manage_personal_information: false,
            manage_account_settings: false,
          },
        },
        employee_doc: {
          view_document: false,
          manage_document: false,
        },
        employee_loan: {
          view_loans: false,
          manage_loans: false,
        },
        personal_info: {
          view_skills: false,
          manage_skills: false,
          view_education: false,
          manage_eduction: false,
          view_bank_details: false,
          manage_bank_details: false,
          view_emergency_contact: false,
          manage_emergency_contact: false,
          view_relation_and_next_of_kin: false,
          manage_relation_and_next_of_kin: false,
          view_identification: false,
          manage_identification: false,
        },
        time_tracking: {
          view_time_tracking: false,
          manage_time_tracking: false,
          view_task_planner: false,
          manage_task_planner: false,
          view_preference: false,
          manage_preference: false,
        },
        employee_performance: {
          selected: 0,
          enable_all: false,
          view_manager_evaluation: false,
          manage_manager_evaluation: false,
          view_employee_evaluation: false,
          manage_employee_evaluation: false,
          view_cdc_decision: false,
          manage_cdc_decision: false,
          view_cdc_plan: false,
          manage_cdc_plan: false,
          view_appraisal: false,
          manage_appraisal: false,
          view_manager_comment: false,
          manage_manager_comment: false,
          view_upcoming_assessment: false,
          manage_upcoming_assessment: false,
          view_assessment_report: false,
          manage_assessment_report: false,
          view_peer_assessment: false,
          manage_peer_assessment: false,
          view_direct_assessment: false,
          manage_direct_assessment: false,
          view_performance_improvement_plan: false,
          manage_performance_improvement_plan: false,
          view_pip_stepper: false,
          manage_pip_stepper: false,
        },
        employee_project: {
          view_project: false,
        },
        notes: {
          selected: 0,
          enable_all: false,
          view_notes: false,
          manage_notes: false,
        },
        leaves: {
          selected: 0,
          enable_all: false,
          view_leaves: false,
          manage_leaves: false,
        },
        bonus_and_benefit: {
          selected: 0,
          enable_all: false,
          view_bonus: false,
          view_benefit: false,
          manage_benefit: false,
          view_tier: false,
          manage_tier: false,
        },
        procurement_requisition:{
          selected: 0,
          enable_all: false,
          view_requisitions:false,
          manage_requisition:false
        }
      },
      direct_report_personal_information: {
        work_info: {
          selected: 0,
          enable_all: false,
          view_employee_status: false,
          manage_employee_status: false,
          view_job_information: false,
          manage_job_information: false,
          view_salary_information: false,
          manage_salary_information: false,
        },
        edit_profile: {
          work_information: {
            selected: 0,
            enable_all: false,
            view_id: false,
            manage_id: false,
            view_hire_date: false,
            manage_hire_date: false,
            view_primary_specialization: false,
            manage_primary_specialization: false,
            view_secondary_specialization: false,
            manage_secondary_specialization: false,
            view_client_readiness: false,
            manage_client_readiness: false,
            view_is_manager: false,
            manage_is_manager: false,
            view_payroll_id: false,
            manage_payroll_id: false,
          },
          personal_information: {
            selected: 0,
            enable_all: false,
            manage_personal_information: false,
            manage_account_settings: false,
          },
        },
        employee_doc: {
          view_document: false,
          manage_document: false,
        },
        employee_loan: {
          view_loans: false,
          manage_loans: false,
        },
        personal_info: {
          view_skills: false,
          manage_skills: false,
          view_education: false,
          manage_eduction: false,
          view_bank_details: false,
          manage_bank_details: false,
          view_emergency_contact: false,
          manage_emergency_contact: false,
          view_relation_and_next_of_kin: false,
          manage_relation_and_next_of_kin: false,
          view_identification: false,
          manage_identification: false,
        },
        time_tracking: {
          view_time_tracking: false,
          manage_time_tracking: false,
          view_task_planner: false,
          manage_task_planner: false,
          view_preference: false,
          manage_preference: false,
        },
        employee_performance: {
          selected: 0,
          enable_all: false,
          view_manager_evaluation: false,
          manage_manager_evaluation: false,
          view_employee_evaluation: false,
          manage_employee_evaluation: false,
          view_cdc_decision: false,
          manage_cdc_decision: false,
          view_cdc_plan: false,
          manage_cdc_plan: false,
          view_appraisal: false,
          manage_appraisal: false,
          view_manager_comment: false,
          manage_manager_comment: false,
          view_upcoming_assessment: false,
          manage_upcoming_assessment: false,
          view_assessment_report: false,
          manage_assessment_report: false,
          view_peer_assessment: false,
          manage_peer_assessment: false,
          view_direct_assessment: false,
          manage_direct_assessment: false,
          view_performance_improvement_plan: false,
          manage_performance_improvement_plan: false,
          view_pip_stepper: false,
          manage_pip_stepper: false,
        },
        employee_project: {
          view_project: false,
        },
        notes: {
          selected: 0,
          enable_all: false,
          view_notes: false,
          manage_notes: false,
        },
        leaves: {
          selected: 0,
          enable_all: false,
          view_leaves: false,
          manage_leaves: false,
        },
        bonus_and_benefit: {
          selected: 0,
          enable_all: false,
          view_bonus: false,
          view_benefit: false,
          manage_benefit: false,
          view_tier: false,
          manage_tier: false,
        },
        procurement_requisition:{
          selected: 0,
          enable_all: false,
          view_requisitions:false,
          manage_requisition:false
        }
      },
      payroll: {
        selected: 0,
        enable_all: false,
        SUPER_ADMIN: false,
        MANAGER: false,
        ACCOUNTANT: false,
        SENIOR_ACCOUNTANT: false,
      },
      leave_management: {
        selected: 0,
        enable_all: false,
        view_leaves_manager: false,
        manage_leaves_manager: false,
        view_manager_dashboard: false,
        manage_manager_dashboard: false,
        view_hr_dashboard: false,
        manage_hr_dashboard: false,
      },
      procurement: {
      selected: 0,
      enable_all: false,
      view_supplier: false,
      manage_supplier: false,
      view_staff_Requisition: false,
      manage_staff_Requisition: false,
      view_cap_approver:false,
      manage_cap_approver:false
      }
    },
  }),
  getters: {},
  actions: {
    // update the store fields ready for editing
    updateAccessStore(
      domain:DomainsType
    ) {
      // update the active access level object
      this.activeAccessLevel.id = domain.id;
      this.activeAccessLevel.name = domain.name;
      // this.activeAccessLevel.description = domain.description;
      // update the assigned employees array
      this.updateAssignedEmployees(domain.domain_heads);
      this.services = domain.apps;
      // update the access level edit state to true since its in the edit state
      this.accessLevelEditState = true;
    },
    updateAssignedEmployees(data:HeadType[]){
      this.assigned_employees = data.map((item)=>{
        item.name = `${item.first_name} ${item.last_name}`;
        return item;
      });
    }
  },
  persist: {
    serializer: {
      deserialize: (value) => JSON.parse(Decrypt(JSON.parse(value))),
      serialize: (value) => JSON.stringify(Encrypt(JSON.stringify(value))),
    },
  },
});
