<template>
  <svg
    width="42"
    height="42"
    viewBox="0 0 24 25"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    class="svg hover:bg-[#FFF0EA] p-2 rounded"
  >
    <path
      d="M22 12.8574C22 18.3774 17.52 22.8574 12 22.8574C6.48 22.8574 2 18.3774 2 12.8574C2 7.33742 6.48 2.85742 12 2.85742C17.52 2.85742 22 7.33742 22 12.8574Z"
      stroke="#8F95B2"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M15.7109 16.0374L12.6109 14.1874C12.0709 13.8674 11.6309 13.0974 11.6309 12.4674V8.36742"
      stroke="#8F95B2"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
</template>
<style>
.svg:hover path {
  stroke: #dd5928;
}
</style>
