<template>
    <svg width="42" height="42" viewBox="0 0 24 24" fill="none" class="p-2" xmlns="http://www.w3.org/2000/svg">
        <path
            d="M22 21.25H21V15C21 10.03 16.97 6 12 6C7.03 6 3 10.03 3 15V21.25H2C1.59 21.25 1.25 21.59 1.25 22C1.25 22.41 1.59 22.75 2 22.75H22C22.41 22.75 22.75 22.41 22.75 22C22.75 21.59 22.41 21.25 22 21.25Z"
            fill="#DD5928" />
        <path
            d="M12 3.75C11.59 3.75 11.25 3.41 11.25 3V2C11.25 1.59 11.59 1.25 12 1.25C12.41 1.25 12.75 1.59 12.75 2V3C12.75 3.41 12.41 3.75 12 3.75Z"
            fill="#DD5928" />
        <path
            d="M4.99945 5.75C4.80945 5.75 4.61945 5.68 4.46945 5.53L3.46945 4.53C3.17945 4.24 3.17945 3.76 3.46945 3.47C3.75945 3.18 4.23945 3.18 4.52945 3.47L5.52945 4.47C5.81945 4.76 5.81945 5.24 5.52945 5.53C5.37945 5.68 5.18945 5.75 4.99945 5.75Z"
            fill="#DD5928" />
        <path
            d="M18.9995 5.75C18.8095 5.75 18.6195 5.68 18.4695 5.53C18.1795 5.24 18.1795 4.76 18.4695 4.47L19.4695 3.47C19.7595 3.18 20.2395 3.18 20.5295 3.47C20.8195 3.76 20.8195 4.24 20.5295 4.53L19.5295 5.53C19.3795 5.68 19.1895 5.75 18.9995 5.75Z"
            fill="#DD5928" />
    </svg>
</template>