<template>
  <svg
    width="42"
    height="42"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    class="svg hover:bg-[#FFF0EA] p-2 rounded"
  >
    <path
      d="M2 22H22"
      stroke="#8F95B2"
      stroke-width="1.5"
      stroke-miterlimit="10"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M12 2C13.6 2.64 15.4 2.64 17 2V5C15.4 5.64 13.6 5.64 12 5V2Z"
      stroke="#8F95B2"
      stroke-width="1.5"
      stroke-miterlimit="10"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M12 5V8"
      stroke="#8F95B2"
      stroke-width="1.5"
      stroke-miterlimit="10"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M17 8H7C5 8 4 9 4 11V22H20V11C20 9 19 8 17 8Z"
      stroke="#8F95B2"
      stroke-width="1.5"
      stroke-miterlimit="10"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M4.58008 12H19.4201"
      stroke="#8F95B2"
      stroke-width="1.5"
      stroke-miterlimit="10"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M7.99023 12V22"
      stroke="#8F95B2"
      stroke-width="1.5"
      stroke-miterlimit="10"
      stroke-linejoin="round"
    />
    <path
      d="M11.9902 12V22"
      stroke="#8F95B2"
      stroke-width="1.5"
      stroke-miterlimit="10"
      stroke-linejoin="round"
    />
    <path
      d="M15.9902 12V22"
      stroke="#8F95B2"
      stroke-width="1.5"
      stroke-miterlimit="10"
      stroke-linejoin="round"
    />
  </svg>
</template>
<style>
.svg:hover path {
  stroke: #dd5928;
}
</style>
