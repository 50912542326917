<template>
    <svg width="42" height="42" viewBox="0 0 24 24" fill="none" class="p-2" xmlns="http://www.w3.org/2000/svg">
        <path
            d="M22.3596 8.48924L22.0696 5.71924C21.6496 2.69924 20.2796 1.46924 17.3497 1.46924H14.9896H13.5097H10.4697H8.98965H6.58965C3.64965 1.46924 2.28965 2.69924 1.85965 5.74924L1.58965 8.49924C1.48965 9.56924 1.77965 10.6092 2.40965 11.4192C3.16965 12.4092 4.33965 12.9692 5.63965 12.9692C6.89965 12.9692 8.10965 12.3392 8.86965 11.3292C9.54965 12.3392 10.7097 12.9692 11.9997 12.9692C13.2896 12.9692 14.4197 12.3692 15.1096 11.3692C15.8797 12.3592 17.0696 12.9692 18.3096 12.9692C19.6396 12.9692 20.8396 12.3792 21.5896 11.3392C22.1896 10.5392 22.4597 9.52924 22.3596 8.48924Z"
            fill="#DD5928" />
        <path
            d="M11.3501 16.879C10.0801 17.009 9.12012 18.089 9.12012 19.369V22.109C9.12012 22.379 9.34012 22.599 9.61012 22.599H14.3801C14.6501 22.599 14.8701 22.379 14.8701 22.109V19.719C14.8801 17.629 13.6501 16.639 11.3501 16.879Z"
            fill="#DD5928" />
        <path
            d="M21.3699 14.6193V17.5993C21.3699 20.3593 19.1299 22.5993 16.3699 22.5993C16.0999 22.5993 15.8799 22.3793 15.8799 22.1093V19.7193C15.8799 18.4393 15.4899 17.4393 14.7299 16.7593C14.0599 16.1493 13.1499 15.8493 12.0199 15.8493C11.7699 15.8493 11.5199 15.8593 11.2499 15.8893C9.46988 16.0693 8.11988 17.5693 8.11988 19.3693V22.1093C8.11988 22.3793 7.89988 22.5993 7.62988 22.5993C4.86988 22.5993 2.62988 20.3593 2.62988 17.5993V14.6393C2.62988 13.9393 3.31988 13.4693 3.96988 13.6993C4.23988 13.7893 4.50988 13.8593 4.78988 13.8993C4.90988 13.9193 5.03988 13.9393 5.15988 13.9393C5.31988 13.9593 5.47988 13.9693 5.63988 13.9693C6.79988 13.9693 7.93988 13.5393 8.83988 12.7993C9.69988 13.5393 10.8199 13.9693 11.9999 13.9693C13.1899 13.9693 14.2899 13.5593 15.1499 12.8193C16.0499 13.5493 17.1699 13.9693 18.3099 13.9693C18.4899 13.9693 18.6699 13.9593 18.8399 13.9393C18.9599 13.9293 19.0699 13.9193 19.1799 13.8993C19.4899 13.8593 19.7699 13.7693 20.0499 13.6793C20.6999 13.4593 21.3699 13.9393 21.3699 14.6193Z"
            fill="#DD5928" />
    </svg>
</template>