<template>
  <svg
    width="42"
    height="42"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    class="svg hover:bg-[#FFF0EA] p-2 rounded"
  >
    <path
      d="M8.67 14H4C2.9 14 2 14.9 2 16V22H8.67V14Z"
      stroke="#8F95B2"
      stroke-width="1.5"
      stroke-miterlimit="10"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M13.3302 10H10.6602C9.56016 10 8.66016 10.9 8.66016 12V22H15.3302V12C15.3302 10.9 14.4402 10 13.3302 10Z"
      stroke="#8F95B2"
      stroke-width="1.5"
      stroke-miterlimit="10"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M20.0001 17H15.3301V22H22.0001V19C22.0001 17.9 21.1001 17 20.0001 17Z"
      stroke="#8F95B2"
      stroke-width="1.5"
      stroke-miterlimit="10"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M12.5202 2.07001L13.0502 3.13C13.1202 3.28 13.3102 3.42 13.4702 3.44L14.4302 3.60001C15.0402 3.70001 15.1902 4.14999 14.7502 4.57999L14.0002 5.32999C13.8702 5.45999 13.8002 5.69999 13.8402 5.86999L14.0502 6.79001C14.2202 7.52001 13.8302 7.80001 13.1902 7.42001L12.2902 6.89001C12.1302 6.79001 11.8602 6.79001 11.7002 6.89001L10.8002 7.42001C10.1602 7.80001 9.77023 7.52001 9.94023 6.79001L10.1502 5.86999C10.1902 5.69999 10.1202 5.44999 9.99023 5.32999L9.25023 4.59C8.81023 4.15 8.95023 3.70998 9.57023 3.60998L10.5302 3.45001C10.6902 3.42001 10.8802 3.28001 10.9502 3.14001L11.4802 2.07999C11.7702 1.49999 12.2302 1.50001 12.5202 2.07001Z"
      stroke="#8F95B2"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
</template>
<style>
.svg:hover path {
  stroke: #dd5928;
}
</style>
