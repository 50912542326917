<template>
  <svg
    width="42"
    height="42"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    class="svg hover:bg-[#FFF0EA] p-2 rounded"
  >
    <path
      d="M12.6609 2.51814L12.6309 2.58814L9.73094 9.31814H6.88094C6.20094 9.31814 5.55094 9.45814 4.96094 9.70814L6.71094 5.52814L6.75094 5.42814L6.82094 5.26814C6.84094 5.20814 6.86094 5.14814 6.89094 5.09814C8.20094 2.06814 9.68094 1.37814 12.6609 2.51814Z"
      stroke="#8F95B2"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M18.0505 9.51814C17.6005 9.37814 17.1205 9.31813 16.6405 9.31813H9.73047L12.6305 2.58814L12.6605 2.51814C12.8105 2.56814 12.9505 2.63814 13.1005 2.69814L15.3105 3.62814C16.5405 4.13814 17.4005 4.66814 17.9205 5.30814C18.0205 5.42814 18.1005 5.53814 18.1705 5.66814C18.2605 5.80814 18.3305 5.94813 18.3705 6.09813C18.4105 6.18813 18.4405 6.27814 18.4605 6.35814C18.7305 7.19814 18.5705 8.22814 18.0505 9.51814Z"
      stroke="#8F95B2"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M21.5217 14.1984V16.1484C21.5217 16.3484 21.5117 16.5484 21.5017 16.7484C21.3117 20.2384 19.3617 21.9984 15.6617 21.9984H7.86172C7.62172 21.9984 7.38172 21.9784 7.15172 21.9484C3.97172 21.7384 2.27172 20.0384 2.06172 16.8584C2.03172 16.6284 2.01172 16.3884 2.01172 16.1484V14.1984C2.01172 12.1884 3.23172 10.4584 4.97172 9.70836C5.57172 9.45836 6.21172 9.31836 6.89172 9.31836H16.6517C17.1417 9.31836 17.6217 9.38836 18.0617 9.51836C20.0517 10.1284 21.5217 11.9884 21.5217 14.1984Z"
      stroke="#8F95B2"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M6.71 5.52814L4.96 9.70814C3.22 10.4581 2 12.1881 2 14.1981V11.2681C2 8.42814 4.02 6.05814 6.71 5.52814Z"
      stroke="#8F95B2"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M21.5186 11.2677V14.1977C21.5186 11.9977 20.0586 10.1277 18.0586 9.52766C18.5786 8.22766 18.7286 7.20766 18.4786 6.35766C18.4586 6.26766 18.4286 6.17766 18.3886 6.09766C20.2486 7.05766 21.5186 9.02766 21.5186 11.2677Z"
      stroke="#8F95B2"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M10 17.1649C10 17.8099 10.495 18.3299 11.11 18.3299H12.365C12.9 18.3299 13.335 17.8749 13.335 17.3149C13.335 16.7049 13.07 16.4899 12.675 16.3499L10.66 15.6499C10.265 15.5099 10 15.2949 10 14.6849C10 14.1249 10.435 13.6699 10.97 13.6699H12.225C12.84 13.6699 13.335 14.1899 13.335 14.8349"
      stroke="#8F95B2"
      stroke-width="0.75"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M11.6641 13V19"
      stroke="#8F95B2"
      stroke-width="0.75"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
</template>
<style>
.svg:hover path {
  stroke: #dd5928;
}
</style>
