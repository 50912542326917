<template>
  <button
    :style="buttonStyle"
    :class="classes"
    class="flex items-center justify-center gap-1"
    @click.prevent.stop="handleClick"
    :disabled="disabled"
    :data-cy="dataCy"
  >
    <slot name="leadingSlot"></slot>
    <Loader v-if="loading" />
    {{ buttonLabel }}
    <slot> </slot>
  </button>
</template>

<script lang="ts" setup>
import { PropType, computed } from "vue";
import Loader from "../loader/InsideButtonLoader.vue";

const props = defineProps({
  variant: {
    type: String as PropType<"primary" | "secondary" | "with_border">,
    default: "primary",
  },
  disabled: {
    type: Boolean,
    default: false,
  },
  buttonLabel: {
    type: String,
    default: "",
  },
  loading: {
    type: Boolean,
    default: false,
  },
  dataCy: {
    type: String,
    default: "",
  },
  class: {
    type: String,
    default: "",
  },
  buttonStyle: {
    type:Object,
    default:{}
  }
});

const emit = defineEmits(["submit"]);

const handleClick = (): void => {
  if (!props.disabled) {
    emit("submit");
  }
};
const classes = computed(() => {
  return `button ${props.variant} ${props.disabled ? "disabled" : ""} ${
    props.class
  }`;
});
</script>

<style scoped>
.button {
  padding: 8px 16px;
  border: none;
  border-radius: 8px;
  cursor: pointer;
  font-weight: 400;
  font-size: 16px;
  text-align: center;
}

.primary {
  background-color: #dd5928;
  color: #fff;
}

.primary:hover {
  background: #b5461d;
}

.secondary {
  color: #dd5928;
}

.with_border {
  border: 1px solid #D8DAE5;
  color: #8f95b2;
}

.disabled {
  opacity: 0.5;
  cursor: not-allowed;
}
</style>
